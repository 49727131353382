import { useEffect, useState } from 'react';

import { Icon, IconButton, Text } from '@optra/kit';

import WorkflowActions from 'components/workflow-actions';
import WorkflowDeployButton from 'components/workflow-deploy-button';
import WorkflowDevices from 'components/workflow-devices';
import WorkflowEditMenu from 'components/workflow-edit-menu';
import WorkflowSkills from 'components/workflow-skills';
import { api, q } from 'config/api';
import { useHasRoles } from 'hooks';
import useIsVisible from 'hooks/use-is-visible';
import { isInProgress } from 'queries/use-deploy-workflow';

export default function Workflow({ workflow, scrollTo = false, expanded, ...rest }) {
  const [innerExpanded, setInnerExpanded] = useState(expanded);
  const [canEdit] = useHasRoles(['admin', 'workflowEditor', 'workflowDeployer']);
  const [canDuplicate] = useHasRoles(['admin', 'workflowEditor', 'workflowDeployer']);
  const [isStarred, setIsStarred] = useState(workflow?.starred);
  const [effect, setEffect] = useState(false);
  const [deleting, setDeleting] = useState(workflow?.deleted);
  const [workflowRef, shouldFetch] = useIsVisible();

  useEffect(() => {
    setInnerExpanded(expanded);
  }, [expanded]);

  useEffect(() => {
    setDeleting(workflow?.deleted);
  }, [workflow?.deleted]);

  const workflowNode = workflowRef.current;
  useEffect(() => {
    if (!scrollTo || !workflowNode) return;
    workflowNode.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [scrollTo, workflowNode]);

  const qc = q.useQueryClient();

  const removeWorkflow = q.useMutation({
    mutationFn: id =>
      api(
        `mutation removeWorkflow($id: ID!) {
          workflow: removeWorkflow(id: $id) {
            id
          }
        }`,
        { id },
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['workflows'] });
      qc.invalidateQueries({ queryKey: ['workflow', r.workflow.id] });
    },
    onError(e) {
      setDeleting(false);
    },
  });

  const toggleWorkflowStar = q.useMutation({
    mutationFn: id =>
      api(
        `mutation toggleWorkflowStar($id: ID!) {
          workflow: toggleWorkflowStar(id: $id) {
            id
            starred
          }
        }`,
        { id },
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['workflows'] });
      qc.invalidateQueries({ queryKey: ['workflow', r.workflow.id] });
      setIsStarred(r.workflow?.starred);
    },
    onError(e) {
      setIsStarred(workflow?.starred);
    },
  });

  const handleStar = () => {
    if (toggleWorkflowStar.isPending) return;
    setIsStarred(starred => !starred);
    if (!isStarred) setEffect(true);
    toggleWorkflowStar.mutate(workflow.id);
  };

  const handleDelete = event => {
    event.stopPropagation();
    event.preventDefault();
    if (window.confirm('Are you sure you want to delete this workflow?')) {
      removeWorkflow.mutate(workflow?.id);
      setDeleting(true);
    }
  };

  return (
    <div className="relative mb-12" ref={workflowRef} {...rest}>
      <div className="md:flex md:items-center md:justify-between mb-4">
        <div className="flex items-center">
          <IconButton
            name={innerExpanded ? 'CaretDown' : 'CaretRight'}
            onClick={() => setInnerExpanded(!innerExpanded)}
            variant="tertiary"
            aria-expanded={innerExpanded}
          />
          <Text size="xl" className="font-medium ml-2">
            {workflow?.name}
          </Text>
          <IconButton
            name="Star"
            color={isStarred ? 'yellow' : 'gray'}
            variant="plain"
            size="xs"
            className={`ml-2 ${effect && 'animate-spring'}`}
            onClick={handleStar}
            onAnimationEnd={() => setEffect(false)}
            disabled={deleting}
            aria-pressed={isStarred}
          />
        </div>
        <div className="mt-2 flex justify-between md:justify-end md:mt-0 md:ml-4 space-x-2">
          <WorkflowDeployButton workflow={workflow} deleting={deleting} />
          {(canEdit || canDuplicate) && (
            <WorkflowEditMenu
              workflowId={workflow?.id}
              onDelete={handleDelete}
              canEdit={canEdit}
              canDuplicate={canDuplicate}
              loading={removeWorkflow.isPending}
              disabled={isInProgress(workflow?.latestDeployment?.status) || deleting}
            />
          )}
        </div>
      </div>

      <div className="lg:grid lg:grid-cols-workflow gap-0">
        <WorkflowDevices
          workflowId={workflow?.id}
          shouldFetch={shouldFetch}
          expanded={innerExpanded}
          disabled={deleting}
        />
        <div className="flex items-center justify-center">
          <Icon
            size="xl"
            name="CaretRight"
            color="primary"
            className="transform rotate-90 lg:rotate-0"
            weight="duotone"
          />
        </div>
        <WorkflowSkills
          workflowId={workflow?.id}
          shouldFetch={shouldFetch}
          expanded={innerExpanded}
          deleting={deleting}
        />
        <div className="flex items-center justify-center">
          <Icon
            size="xl"
            name="CaretRight"
            color="primary"
            className="transform rotate-90 lg:rotate-0"
            weight="duotone"
          />
        </div>
        <WorkflowActions
          workflowId={workflow?.id}
          shouldFetch={shouldFetch}
          expanded={innerExpanded}
          disabled={deleting}
        />
      </div>
    </div>
  );
}
